import React from "react";
import { graphql } from "gatsby";
import { useBreadcrumb } from "gatsby-plugin-breadcrumb";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Breadcrumbs from "../components/Navigation/breadcrumbs";
import Stats from "../components/Stats/stats";
import Clients from "../components/Clients/clients";
import Map from "../components/Map/map";
import Team from "../components/Company/team";
import Seo from "../components/seo";
import Details from "../components/Company/details";
import Btn from "../components/Buttons/btn";
import useFlags from "../components/useFlags";

const getDate = (minus) => {
  const now = new Date();
  return parseInt(now.getFullYear()) - parseInt(minus);
};
const Company = ({ path, location, data: { mdx } }) => {
  const {
    show_stats,
    show_details_company,
    show_team,
    show_clients,
    show_map,
  } = useFlags();
  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: mdx?.frontmatter?.title,
  });
  return (
    <>
      <Seo
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.description}
        pathname={path}
      />
      <Breadcrumbs crumbs={crumbs} />
      <div className="mb-5">
        <div className="container">
          <h1 className="page-title">{mdx?.frontmatter?.title}</h1>
        </div>
        <div className="container container__page container__company">
          <MDXProvider
            components={{
              span: (props) => <span {...props} style={{ color: "#ffcb00" }} />,
              Btn: (props) => (
                <div className="row company__btn-portfolio">
                  <Btn {...props}></Btn>
                </div>
              ),
              Date: (props) => <span {...props}> {getDate(props.minus)}</span>,
              strong: (props) => (
                <strong {...props} style={{ fontWeight: "900" }} />
              ),
            }}
          >
            <MDXRenderer frontmatter={mdx.frontmatter}>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </div>
        {show_stats && <Stats />}
        {show_details_company && <Details />}
        {show_team && <Team />}
        {show_clients && <Clients />}
        {show_map && <Map />}
      </div>
    </>
  );
};
export default Company;

export const query = graphql`
  query Company($locale: String!, $slug: String!) {
    mdx(fields: { locale: { eq: $locale }, slug: { eq: $slug } }) {
      frontmatter {
        title
        description
      }
      body
    }
  }
`;
