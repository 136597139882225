import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./company.css";

const Worker = ({ name, position, image }) => {
  const img = getImage(image);
  return (
    <li className="hex">
      <div>
        <GatsbyImage
          image={img}
          imgClassName="team__image"
          className="img-fluid team__container__image"
          alt="photo-team"
        />
        <div className="curtain-top"></div>
        <span className="team__person-name">{name}</span>
        <div className="curtain-bottom"></div>
        <span className="team__position">{position}</span>
      </div>
    </li>
  );
};

export default Worker;
