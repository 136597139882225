import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Worker from "./worker";
import { useLocale } from "../../hooks/locale";
import "./company.css";

const Team = () => {
  const { allMarkdownRemark } = useStaticQuery(query);
  const { locale } = useLocale();

  return (
    <div className="container mt-5">
      <ul id="team">
        {allMarkdownRemark.edges.map(({ node }, index) => {
          return (
            <Worker
              key={`${index}`}
              name={node.frontmatter.name}
              position={
                node.frontmatter[`position_${locale}`] ||
                node.frontmatter.position_en
              }
              image={node.frontmatter.image}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default Team;

export const query = graphql`
  query Team {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(team)/.*.md$/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            name
            position_pl
            position_en
            position_de
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  placeholder: BLURRED
                  formats: [WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`;
