import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ServiceList from "../Service/serviceList";
import AnimateTitle from "../Title/animateTitle";
import useComponents from "../useComponents";

const Details = () => {
  const { rawData } = useStaticQuery(query);
  const { company } = useComponents(rawData);
  return (
    <section className="container section__margin ">
      <AnimateTitle items={company.title} className="col-12 text-center mb-3" />
      <div className="row">
        <ServiceList content={company.content} />
      </div>
    </section>
  );
};

export default Details;

const query = graphql`
  query company {
    rawData: allFile(filter: { sourceInstanceName: { eq: "components" } }) {
      edges {
        node {
          name
          translations: childComponentsJson {
            company {
              title {
                text
                className
              }
              content {
                title
                thumbnail {
                  className
                }
                description
              }
            }
          }
        }
      }
    }
  }
`;
